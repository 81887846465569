import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Badge from "../components/badge"
import Img from "gatsby-image"

import { } from "@fancyapps/ui/src/Fancybox/Fancybox.js";

const worksPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  let workBanner = post.frontmatter.workBanner.childImageSharp.fluid
  // let videoUrl = post.frontmatter.workVideo ? "/" + post.frontmatter.workVideo + ".mp4" : null;
  let videoUrl = post.frontmatter.workVideo ? "https://cdn.onurboz.com/static/" + post.frontmatter.workVideo + '/' + post.frontmatter.workVideo + ".mp4" : null;


  if (typeof window !== `undefined` && !document.querySelector('body').classList.contains('expand-video-listener-initialized')) {
    document.addEventListener('click', function (e) {
      var dummy = e.target.closest('.browser .video');
      dummy = dummy && dummy.closest('.browser');
      dummy && dummy.classList.toggle('expand');
    });
    document.querySelector('body').classList.add('expand-video-listener-initialized')
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title + ' · ' + post.frontmatter.workType + ' · ' + post.frontmatter.type}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="container container-max-md">
        <article
          className="works-post"
          itemScope
          itemType="http://schema.org/Article"
          lang={post.frontmatter.lang}
        >
          <header className="mb-4">
            <h1 className="h4 m-0" itemProp="headline">{post.frontmatter.title}</h1>
            <h2 className="h6 text-400 m-0" itemProp="headline">{post.frontmatter.description}</h2>
          </header>
          <section>
            <div className="browser user-select-none">
              <div aria-hidden="true" className="controls" data-title={post.frontmatter.workTitle}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="banner">
                <Img className="borderless pe-none" fluid={workBanner} />
                {videoUrl !== null &&
                  <div className="video" title={post.frontmatter.workTitle}>
                    <div className="browser user-select-none pe-none">
                      <div aria-hidden="true" className="controls" data-title={post.frontmatter.workTitle}>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      <div className="ratio ratio-4x3 pe-none">
                        <video poster={workBanner.base64} autoPlay={true} controls="" muted loop playsInline>
                          <source src={videoUrl}
                            type="video/mp4"></source>
                          Sorry, your browser doesn't support embedded videos.
                        </video>

                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="bottom bg-800">
                <div className="row gx-0">
                  <div className="col-6 d-flex align-items-center">
                    <div className="w-100">
                      <h3 className="h5 m-0 text-truncate">
                        {post.frontmatter.workTitle}
                      </h3>
                      <p className="text-muted m-0 text-truncate"><small>{post.frontmatter.workDate}</small></p>
                    </div>
                  </div>
                  <div className="col-6 d-flex align-items-center justify-content-end">
                    <a className="d-inline-flex py-2 px-4 bg-700 hover--bg-600 rounded text-white" href="./" data-fancybox data-caption={post.frontmatter.title} data-type="iframe" data-src={post.frontmatter.workDemo}>
                      Preview
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
            className="entry"
          />

          <Badge badges={post.frontmatter.workBadge || []} />

          <footer className="my-4">
            <Link title="Go to about page" className="link-light" to="/about">
              <Bio />
            </Link>
          </footer>
        </article>
        <nav className="blog-post-nav mt-5">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </Layout>
  )
}

export default worksPostTemplate

export const pageQuery = graphql`
  query works(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        lang
        type
        workDate
        workType
        workTitle
        workBadge
        workVideo
        workBanner {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        workDemo
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
